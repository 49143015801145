import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import RequestPage from './pages/UserPage';
import HistoryPage from './pages/history';
import Notifications from './pages/notifications';
import LoginPage from './pages/LoginPage';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Page404 from './pages/Page404';
import NewUser from './pages/NewUser';
import TeamPage from './pages/ProductsPage';
import HelpPage from './pages/HelpPage';
import AccountPage from './pages/AccountPage';
import DashboardAppPage from './pages/DashboardAppPage';
import Loading from './pages/loadData'
import OnboardingPage from './pages/onboarding';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'queue', element: <RequestPage /> },
        { path: 'history', element: <HistoryPage /> },
        { path: 'team', element: <TeamPage /> },
        { path: 'help', element: <HelpPage /> },
        { path: 'user', element: <AccountPage /> },
        { path: 'notifications', element: <Notifications /> },
        { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'onboarding',
      element: <OnboardingPage />,
    },
    {
      path: 'loading',
      element: <Loading />,
    },
    {
      path: 'newuser',
      element: <NewUser />,
    },
    {
      path: 'forgotpassword',
      element: <ForgotPassword />,
    },
    {
      path: 'resetpassword',
      element: <ResetPassword />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}

import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Card, Box, Stack } from '@mui/material';
import Iconify from '../components/iconify';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function NewUser() {
  return (
    <>
      <Helmet>
        <title> New User | Sessions HQ </title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Welcome to Sessions HQ!
          </Typography>

          <Typography variant="secondary" paragraph>
            It looks like you are new user, welcome! To get started, pick a plan below. You will gain immediate access, upon completion.
          </Typography>

          <Stack direction={'row'}>
            <Card sx={{ width: '400px ', margin: '5px'}}>
              <h2>Standard</h2>
              <ul>
              <li>One request at a time</li>
              <li>Unlimited Revisions</li>
              <li>100% Original Custom Audio</li>
              <li>Unlimited Users & Brands</li>
              <li>Instant Access</li>
              <li>Easy payments via Stripe</li>
              <li>Pause & continue subscription</li>
              <li>6 month locked-in rate</li>
              </ul>
              <Button sx={{m: 3}} to="https://buy.stripe.com/dR66q6bCO7G05Xi4gi" size="large" variant="contained" component={RouterLink}>
                START NOW AT $5,995/MO 
              </Button>
              <Typography variant="subtitle2" paragraph>
                This will take you to Stripe.
              </Typography>
            </Card>
            
            <Card sx={{ width: '400px ', margin: '5px'}}>
              <h2>Pro</h2>
              <ul>
              <li>Two request at a time</li>
              <li>Unlimited Revisions</li>
              <li>100% Original Custom Audio</li>
              <li>Unlimited Users & Brands</li>
              <li>Instant Access</li>
              <li>Easy payments via Stripe</li>
              <li>Pause & continue subscription</li>
              <li>6 month locked-in rate</li>
              </ul>
              <Button sx={{m: 3}} to="https://buy.stripe.com/eVa15McGSf8sdpK28b" size="large" variant="contained" component={RouterLink}>
                START NOW AT $8,995/MO 
              </Button>
              <Typography variant="subtitle2" paragraph>
                This will take you to Stripe.
              </Typography>
            </Card>
          </Stack>
        </StyledContent>
      </Container>
    </>
  );
}

import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, TextField, Typography, Divider, Stack, Button, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';
import { supabase } from '../supabaseClient';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ForgotPassword() {
  const mdUp = useResponsive('up', 'md');
  const [email, setEmail] = useState('');

  const notifySuccess = () => toast.success('Success, password reset request sent to your email.');

  const navigate = useNavigate();

  const handleSubmit = async () => {
    const { data, error } = await supabase.auth
      .resetPasswordForEmail(email, {
        redirectTo: 'https://localhost:3000/resetpassword',
      });

      notifySuccess();
    
      navigate('/login');
  }

  return (
    <>
      <Helmet>
        <title> Forgot Password | Sessions HQ </title>
      </Helmet>

      <StyledRoot>
      <Toaster/>
        {mdUp && (
          <StyledSection>
            <center><Box
              component="img"
              src="/assets/logo.png"
              sx={{ width: 270, height: 40, cursor: 'pointer'}}
            /></center>
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Password Reset Request
            </Typography>

            <br/>

            <TextField 
              name="email" 
              label="Email address" 
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />

            <br/>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleSubmit}>
            Submit Password Reset Request
          </LoadingButton>

            {/* <LoginForm isLogin={login}/> */}
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import toast, { Toaster } from 'react-hot-toast';
import { supabase, SetData } from '../../../supabaseClient'
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm({ isLogin }) {

  const notifySuccess = () => toast.success('Success, check your email for more information.');
  const notifyError = () => toast.error('Invalid password.');

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  

  useEffect(() => {
    supabase.auth.onAuthStateChange((event) => {
      if (event === 'SIGNED_IN') {
        navigate("/loading");
        
      }
    })
  }, [])
  
  const handleClick = (email, pass) => {
    SignInWithEmail(email, pass);
  };

  async function SignInWithEmail(mail, pass) {
    if (isLogin) {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: mail, password: pass,
      })

      if (error) notifyError();
    }
    else {
      const { data, error } = await supabase.auth.signUp({
        email: mail,
        password: pass,
      })

      if (!error) notifySuccess();
    }
  }

  return (
    <>
    <Toaster/>
      <Stack spacing={3}>
        <TextField 
          name="email" 
          label="Email address" 
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </Stack>
      
      <br/>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={() => handleClick(email, password)}>
        {isLogin ? 'Login' : 'Sign Up'}
      </LoadingButton>
    </>
  );
} 

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Container, CardContent, CardActions, Divider, Drawer, Card, Modal, Typography, Avatar, Stack } from '@mui/material';
import { useUserStore, useCompanyStore } from '../../../supabaseClient'
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import Iconify from '../../../components/iconify';
import '../../../main.css'
//
import navConfig from './config';

// ----------------------------------------------------------------------

const NAV_WIDTH = 325;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [popUp, setPopup] = useState(false);
  const [openFeature, setOpenFeature] = useState(false);

  const handleCloseFeature = () => {
    setOpenFeature(false);
  }
  
  const onClosePopup = () => {
    setPopup(false);
  }

  const { userData } = useUserStore(
    (state) => ({ userData: state.user })
  )

  const { companyData } = useCompanyStore(
    (state) => ({ companyData: state.company })
  )

  const isDesktop = useResponsive('up', 'lg')

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 1, py: 1, display: 'inline-flex', paddingTop: '10%', paddingBottom: '10%', paddingLeft: '5%', paddingRight: '5%' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={userData.photo} alt="photoURL" />

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {userData.name}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {userData.username}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Container>
        <Modal classNames={{modal: 'modalBox'}} sx={{paddingTop: '15%'}} open={popUp} onClose={onClosePopup} center>
        <Card sx={{marginX: '30%'}}>
          <CardContent>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Typography
                gutterBottom
                variant="h6"
              >
                {userData.name}, thank you for being part of Sessions HQ!
              </Typography>
              <Typography
                color="text.secondary"
                variant="body2"
                textAlign='center'
              >
                We will give you $1,000 for every person you refer that works with Sessions HQ!
              </Typography>
              <Typography
                color="text.secondary"
                variant="body2"
              >
                {userData.createdAt}
              </Typography>
            </Box>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              fullWidth
              variant="text"
              onClick={() => onClosePopup()}
            >
              Sounds great!
            </Button>
          </CardActions>
        </Card>
        </Modal>
      </Container>
      
      
      <Stack direction={'row'} spacing={1} my={1} justifyContent={'center'}>
      <Button target='_blank' href='https://ynobcgalfvsdrpkcvwyp.supabase.co/storage/v1/object/public/photos/8-22%20tos.pdf?t=2023-08-23T04%3A03%3A48.106Z' variant="outlined" size={'small'}
        onClick={() => {

        }}>
          TOS
        </Button>

        <Button target='_blank' href='https://ynobcgalfvsdrpkcvwyp.supabase.co/storage/v1/object/public/photos/8-22%20permitted%20usage.pdf?t=2023-08-23T04%3A02%3A51.695Z' variant="outlined" size={'small'}
        onClick={() => {

        }}>
          Usage
        </Button>

        <Button target='_blank' href='https://xdpjkvrmmcqjfxhxtswz.supabase.co/storage/v1/object/public/Sessions%20Web/Sessions%20HQ%20-%20Privacy%20Policy%20(1).pdf' variant="outlined" size={'small'}
        onClick={() => {
        }}>
          Privacy
        </Button>

        <Button target='_blank' variant="outlined" size={'small'}
        onClick={() => {
          setPopup(true);
        }}>
          Referral
        </Button>

        </Stack>
    </Scrollbar>
  );
  
  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

import { useEffect } from "react";
import { createClient } from "@supabase/supabase-js";
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

const supabase = createClient(
    "https://ynobcgalfvsdrpkcvwyp.supabase.co",
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inlub2JjZ2FsZnZzZHJwa2N2d3lwIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTE4MTc2MjIsImV4cCI6MjAwNzM5MzYyMn0.RfjhQ8DDY2MmkDDxOasAl6bNppOdzOOTyrnbR27v8AY"
)


export const useUserStore = create(persist((set) => ({
  user: {},
  changeUser: (input) => set({ user: input }),
}), {name: 'user-storage'}));

export const useCompanyStore = create(persist((set) => ({
  company: {},
  changeCompany: (input) => set({ company: input }),
}), {name: 'company-storage'}));

export const useAdminCompanyStore = create(persist((set) => ({
  adminCompany: {},
  changeAdminCompany: (input) => set({ adminCompany: input }),
}), {name: 'admin-company-storage'}));

export const useRequestStore = create(persist((set) => ({
  requests: {},
  changeRequests: (input) => set({ requests: input }),
}), {name: 'request-storage'}));

export const useNotificationStore = create(persist((set) => ({
  notifications: {},
  changeNotifications: (input) => set({ notifications: input }),
}), {name: 'notification-storage'}));

export const useCommentStore = create(persist((set) => ({
  comments: {},
  changeComments: (input) => set({ comments: input }),
}), {name: 'comment-storage'}));

export default async function SetData() {

  useEffect(() => {

    const updateRequests = async (payload) => {
      if (payload.eventType === 'UPDATE') {
        const { data: tempReqs, errorReq } = await supabase
          .from('requests')
          .select()
          .eq('company', tempCompany);

    
        const updatedReqs = tempReqs.map((item) => {
          if (item.id === payload.old.id) {
            return payload.new;
          }
          return item;
        });
    
        setRequests(updatedReqs);
      }
    
      if (payload.eventType === 'INSERT') {
        const { data: tempReqs, errorReq } = await supabase
          .from('requests')
          .select()
          .eq('company', tempCompany);

          const updatedReqs = tempReqs.map((item) => {
            if (item.id === payload.old.id) {
              return payload.new;
            }
            return item;
          });
      
          setRequests(updatedReqs);
      
      }
    
      if (payload.eventType === 'DELETE') {
        const { data: tempReqs, errorReq } = await supabase
          .from('requests')
          .select()
          .eq('company', tempCompany);
    
        const updatedReqs = tempReqs.filter((item) => item.id !== payload.old.id);
        setRequests(updatedReqs);
      }
    }
    
    const updateNotifications = async (payload) => {
      if (payload.eventType === 'UPDATE') {
        const { data: tempNoti, errorReq } = await supabase
          .from('notifications')
          .select()
          .eq('username', tempUsername);
    
        const updatedNoti = tempNoti.map((item) => {
          if (item.id === payload.old.id) {
            return payload.new;
          }
          return item;
        });
    
        setNotifications(updatedNoti);
      }
    
      if (payload.eventType === 'INSERT') {
        const { data: tempNoti, errorReq } = await supabase
          .from('notifications')
          .select()
          .eq('username', tempUsername);
    
          const updatedNoti = tempNoti.map((item) => {
            if (item.id === payload.old.id) {
              return payload.new;
            }
            return item;
          });
      
          setNotifications(updatedNoti);
  
      }
    }

      const updateUser = async (payload) => {
        if (payload.eventType === 'UPDATE') {
          const { data: tempUser, errorReq } = await supabase
            .from('users')
            .select()
            .eq('username', tempUsername);
      
          const updatedUser = tempUser.map((item) => {
            if (item.id === payload.old.id) {
              return payload.new;
            }
            return item;
          });
      
          setUser(updatedUser[0]);
        }
      
        if (payload.eventType === 'INSERT') {
          const { data: tempUser, errorReq } = await supabase
            .from('users')
            .select()
            .eq('username', tempUsername);
      
            const updatedUser = tempUser.map((item) => {
              if (item.id === payload.old.id) {
                return payload.new;
              }
              return item;
            });
        
            setUser(updatedUser[0]);
    
        }
      }
      
      const updateComments = async (payload) => {
        if (payload.eventType === 'UPDATE') {
          const { data: tempComments, errorReq } = await supabase
            .from('comments')
            .select()
            .eq('company', tempCompany);
      
          const updatedComments = tempComments.map((item) => {
            if (item.id === payload.old.id) {
              return payload.new;
            }
            return item;
          });
      
          setComments(updatedComments);
        }
      
        if (payload.eventType === 'INSERT') {
          const { data: tempComments, errorReq } = await supabase
            .from('comments')
            .select()
            .eq('company', tempCompany);
      
            const updatedComments = tempComments.map((item) => {
              if (item.id === payload.old.id) {
                return payload.new;
              }
              return item;
            });
        
            setComments(updatedComments);
        
        }
      }
      
      const updateCompany = async (payload) => {
        if (payload.eventType === 'UPDATE') {
          const { data: tempComData, errorReq } = await supabase
            .from('company')
            .select()
            .eq('name', tempCompany);
      
          const updatedComData = tempComData.map((item) => {
            if (item.id === payload.old.id) {
              return payload.new;
            }
            return item;
          });
      
          setCompany(updatedComData[0]);
        }
      
        if (payload.eventType === 'INSERT') {
          const { data: tempComData, errorReq } = await supabase
            .from('company')
            .select()
            .eq('name', tempCompany);
      
            const updatedComData = tempComData.map((item) => {
              if (item.id === payload.old.id) {
                return payload.new;
              }
              return item;
            });
        
            setCompany(updatedComData[0]);
    
        }
      }

    const realtimeNotifications = supabase
      .channel('Notifications-db-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'notifications',
        },
        (payload) => updateNotifications(payload)
      )
      .subscribe()
  
      const realtimeRequests = supabase
      .channel('Requests-db-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'requests',
        },
        (payload) => updateRequests(payload)
      )
      .subscribe()
  
      const realtimeUsers = supabase
      .channel('Users-db-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'users',
        },
        (payload) => updateUser(payload)
      )
      .subscribe()
      
      const realtimeCompany = supabase
      .channel('Company-db-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'company',
        },
        (payload) => updateCompany(payload)
      )
      .subscribe()

      const realtimeComments = supabase
      .channel('Comments-db-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'comments',
        },
        (payload) => updateComments(payload)
      )
      .subscribe()
  
  }, [])

    const setUser = useUserStore((state) => state.changeUser);

    const setCompany = useCompanyStore((state) => state.changeCompany);

    const setAdminCompany = useAdminCompanyStore((state) => state.changeAdminCompany);

    const setRequests = useRequestStore((state) => state.changeRequests);

    const setNotifications = useNotificationStore((state) => state.changeNotifications);

    const setComments = useCommentStore((state) => state.changeComments);

    let tempCompany;
    let tempUsername;
    let tempRequests;
    let tempNotifications;

    await supabase.auth.getUser().then(async (value) => {
        if (value.data?.user && !value.data.user.email.includes('@sessionshq.com')) {
          const { data: userData, errorUser } = await supabase
              .from('users')
              .select()
              .eq('email', value.data.user.email);

              setUser(userData[0]);
              tempUsername = userData[0].username;
              tempCompany = userData[0].company;
        
          const { data: companyData, errorCompany } = await supabase
              .from('company')
              .select()
              .eq('name', tempCompany);

              setCompany(companyData[0]);

          const { data: requestData, errorReq } = await supabase
              .from('requests')
              .select()
              .eq('company', tempCompany);

              setRequests(requestData);
              tempRequests = requestData;

          const { data: notiData, errorNoti } = await supabase
              .from('notifications')
              .select()
              .eq('username', tempUsername);

              setNotifications(notiData);
              tempNotifications = notiData;
        
          const { data: commentData, errorComment } = await supabase
              .from('comments')
              .select()
              .eq('company', tempCompany);

              setComments(commentData);
        }
        else {
          const { data: userData, errorUser } = await supabase
              .from('users')
              .select()
              .eq('email', value.data.user.email);

              setUser(userData[0]);
              tempUsername = userData[0].username;
              tempCompany = userData[0].company;
        
          const { data: companyData, errorCompany } = await supabase
              .from('company')
              .select()
              .eq('name', tempCompany);

              setCompany(companyData[0]);

          const { data: adminCompanyData, errorAdminCompany } = await supabase
              .from('company')
              .select()

              setAdminCompany(adminCompanyData);

          const { data: requestData, errorReq } = await supabase
              .from('requests')
              .select()

              setRequests(requestData);
              tempRequests = requestData;

          const { data: notiData, errorNoti } = await supabase
              .from('notifications')
              .select()
              .eq('username', tempUsername);

              setNotifications(notiData);
              tempNotifications = notiData;
        
          const { data: commentData, errorComment } = await supabase
              .from('comments')
              .select()

              setComments(commentData);
        }
      })

}

export { supabase, SetData };
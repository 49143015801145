import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import '../main.css'
import { Modal } from 'react-responsive-modal';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  TableRow,
  TextField,
  MenuItem,
  TableBody,
  TableHead,
  Select,
  TableCell,
  Container,
  Typography,
  FormControl,
  FormGroup,
  RadioGroup,
  FormLabel,
  Radio,
  FormControlLabel,
  InputLabel,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';
import { useUserStore, supabase, useRequestStore, useNotificationStore, useCompanyStore } from '../supabaseClient'
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


export default function Notifications() {
  const [open, setOpen] = useState(null);

  const [sendNoti, setSendNoti] = useState(null);

  const [notiText, setNotiText] = useState('');

  const [notiTarget, setNotiTarget] = useState('');

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [notiList, setNotiList] = useState([]);

  const navigate = useNavigate();

  const notifySuccessSend = () => toast.success('Sent successfully.');

  const { userData } = useUserStore(
    (state) => ({ userData: state.user })
  )

  const { companyData } = useCompanyStore(
    (state) => ({ companyData: state.company })
  )

  const { reqData } = useRequestStore(
    (state) => ({ reqData: state.requests })
  )

  const { notiData } = useNotificationStore(
    (state) => ({ notiData: state.notifications })
  )

  async function getNotis() {
    setNotiList(notiData);
  }


  const handleCloseMenu = () => {
    setOpen(null);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleSendNotification = async () => {
    if (notiTarget === '@everyone') {
      const { data, error } = await supabase
        .from('users')
        .select('username');

      data.forEach(async (user) => {
        const { error } = await supabase
        .from('notifications')
        .insert({ username: user.username, state: 'unseen', content: notiText });
      })

      notifySuccessSend();
    }
    else {
      const { error } = await supabase
        .from('notifications')
        .insert({ username: notiTarget, state: 'unseen', content: notiText });

      if (!error) notifySuccessSend();
    }

    setNotiTarget('');
    setNotiText('');

    setSendNoti(null);
  }

  useEffect(() => {
    getNotis();
  }, [notiData]);

  useEffect(() => {
    if (!userData.name) {
      navigate("/login", {replace: true} );
    }
  }, []);

  return (
    <>
      <Helmet>
        <title> Notifications | Sessions HQ </title>
      </Helmet> 
      <Toaster/>
      <Modal classNames={{modal: 'modalBox'}} open={sendNoti} onClose={() => {setSendNoti(null)}} center>
        <h2>Send a notification</h2>
        <p>Only use @usernames or @everyone</p>

        <TextField
        id="username-input"
        label="Username"
        fullWidth
        onChange={(e) => setNotiTarget(e.target.value)}
        />

        <br/><br/>    

        <TextField
        id="text-input"
        label="Text"
        fullWidth
        onChange={(e) => setNotiText(e.target.value)}
        />

        <br/><br/>

        <Button variant="contained" color="info" fullWidth startIcon={<Iconify icon="mingcute:send-fill" />}
          onClick={() => {
            handleSendNotification();
          }}>
            Send Notification
        </Button>
      </Modal>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Notifications
          </Typography>

          <Button sx={{ display: companyData.plan !== 'ADMIN' ? 'none' : ''}} variant="contained" color="info" startIcon={<Iconify icon="mingcute:send-fill" />}
          onClick={() => {
            setSendNoti(true);
          }}>
            Send Notification
        </Button>
        </Stack>
        
        <br/><br/>

        <Card>
          <Scrollbar>
          <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Notification</TableCell>
            <TableCell align="right">User</TableCell>
            <TableCell align="right">Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notiList.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.content}
              </TableCell>
              <TableCell align="right">{row.username}</TableCell>
              <TableCell align="right">{new Date(row.created_at).toLocaleString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
          </Scrollbar>

          
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        

        <MenuItem sx={{ color: 'info' }}>
          <Iconify icon={'material-symbols:download'} sx={{ mr: 2 }} />
          Download
        </MenuItem>
      </Popover>
    </>
  );
}



import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Stack,
  Typography
} from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {useDropzone} from 'react-dropzone';
import { toast, Toaster } from 'react-hot-toast';
import {useUserStore, supabase, useCompanyStore} from '../../supabaseClient'


export const AccountProfile = () => {

  const toastId = useRef(null)

  const notifySuccess = () => toast.success('Profile picture was successfully changed.');

  const navigate = useNavigate();

  const { userData } = useUserStore(
    (state) => ({ userData: state.user })
  )

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

  const files = acceptedFiles.map(file => (
      <li key={file.path}>
        {file.path} -  {(file.size / 10**6).toFixed(2)} MB
      </li>
    ));

  useEffect(() => {
      const handleUpload = async () => {
          if (acceptedFiles.length > 0) {
            toastId.current = toast.loading('Uploading new photo...');
              const randString = Math.random().toString(36).slice(2, 7);
              const { data, error } = await supabase
                  .storage
                  .from('photos')
                  .upload(`${randString}-${acceptedFiles[0].name}`, acceptedFiles[0], {
                      cacheControl: '3600',
                      upsert: false
                  })
              
              const pfpURL = `https://ynobcgalfvsdrpkcvwyp.supabase.co/storage/v1/object/public/photos/${randString}-${acceptedFiles[0].name}`

              const { errorPhoto } = await supabase
                  .from('users')
                  .update({ photo: pfpURL })
                  .eq('email', (userData.email))

              if (!errorPhoto && !error) {
                toast.dismiss(toastId.current);
              }
              else {
                toast.dismiss(toastId.current);
              }
          } 
      }
      handleUpload();
  }, [acceptedFiles])

  return (
  <Card>
    <Toaster/>
    <CardContent>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Avatar
          src={userData.photo}
          sx={{
            height: 80,
            mb: 2,
            width: 80
          }}
        />
        <Typography
          gutterBottom
          variant="h5"
        >
          {userData.name}
        </Typography>
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {userData.company} | {userData.username} 
        </Typography>
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {userData.createdAt}
        </Typography>
      </Box>
    </CardContent>
    <Divider />
    <CardActions>
      <Stack direction={'column'}>
        <div>
        <section className="container">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className='fileDrop'>
              Drag 'n' drop a photo here to change your profile picture (PNG or JPEG/JPG only!)
            </div>
          </div>
        </section>
        </div>
      </Stack>
    </CardActions>
  </Card>
  )
};

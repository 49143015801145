import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Container, Stack, Typography, Button, FormControl, InputLabel, MenuItem, TextField, FormControlLabel, Radio, Select, FormLabel, RadioGroup } from '@mui/material';
import { Modal } from 'react-responsive-modal';
import toast, { Toaster } from 'react-hot-toast';
// components
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
import Iconify from '../components/iconify';
import '../main.css'
import {useUserStore, supabase, useCompanyStore} from '../supabaseClient'

// mock
import PRODUCTS from '../_mock/products';

// ----------------------------------------------------------------------

export default function TeamPage() {
  const [openFilter, setOpenFilter] = useState(false);
  const [addTeam, setAddTeam] = useState(null);
  const [newRequestName, setNewRequestName] = useState('');
  const [newRequestEmail, setNewRequestEmail] = useState('');

  const notifySuccessInvite = () => toast.success('Invitation was sent successfully.');
  const notifyErrorInvite = () => toast.error('Something went wrong...');

  const { userData } = useUserStore(
    (state) => ({ userData: state.user })
  )

  const { companyData } = useCompanyStore(
    (state) => ({ companyData: state.company })
  )

  const onOpenModal = () => setAddTeam(true);
  const onCloseModal = () => setAddTeam(false);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleName = (event) => {
    setNewRequestName(event.target.value);
  };

  const handleEmail = (event) => {
    setNewRequestEmail(event.target.value);
  };

  const inviteTeamMember = async () => {
    const { error } = await supabase
      .from('users')
      .insert({ name: newRequestName, email: newRequestEmail, company: userData.company, role: 'TEAM'});

    const { data, error2 } = await supabase.auth.admin.inviteUserByEmail(newRequestEmail);

    if (!error && !error2) {
      notifySuccessInvite();
    }
    else {
      notifyErrorInvite();
    }
  }

  const navigate = useNavigate();

  useEffect(() => {
    if (!userData.name) {
      navigate("/login", {replace: true} );
    }
  }, []);

  return (
    <>
      <Helmet>
        <title> Team | Sessions HQ</title>
      </Helmet>

      <Container>
        
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {companyData.plan === 'ADMIN' ? 'Clients' : 'Team'}
        </Typography>
          <Button sx={{ display: companyData.plan === 'ADMIN' ? 'none' : ''}} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={() => {
            onOpenModal();
          }}>
            Add Team Member
          </Button>
        </Stack>

        <ProductList />

        <Modal classNames={{modal: 'modalBox'}} open={addTeam} onClose={onCloseModal} center>
        <h2>Add Team Member</h2>

        <TextField
          id="title-name"
          label="Name"
          fullWidth
          onChange={handleName}
        />

        <br/><br/>

        <TextField
          id="desc-email"
          label="Email"
          fullWidth
          onChange={handleEmail}
        />

        <br/><br/>

        <Button variant="outlined" color="error" fullWidth startIcon={<Iconify icon="foundation:x" />}
          onClick={() => {
            onCloseModal();
          }}>
            Cancel
        </Button>

          <br/><br/>

        <Button variant="contained" fullWidth startIcon={<Iconify icon="mdi:check-bold" />}
          onClick={() => {
            inviteTeamMember();
          }}>
            Add Member To Your Team
        </Button>

        

      </Modal>
        
      </Container>
      
    </>
  );
}

import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { SetData, useUserStore, useCompanyStore } from '../supabaseClient'


export default function LoadData() {
    const navigate = useNavigate();

    const { userData } = useUserStore(
        (state) => ({ userData: state.user })
      )

    const { companyData } = useCompanyStore(
        (state) => ({ companyData: state.company })
      )

    SetData().then(() => {
        if (companyData?.plan === 'PRO' || 'BASIC' || 'ADMIN') {
            if (userData.firstTime) navigate("/onboarding");
            else navigate("/dashboard/app");
        }
        else navigate("/newuser");
    })
}
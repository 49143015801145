import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Container, Stack, Typography, Button, Grid, Box, FormControl, InputLabel, MenuItem, TextField, FormControlLabel, Radio, Select, FormLabel, RadioGroup, Card, CardActions, CardContent } from '@mui/material';
import { Modal } from 'react-responsive-modal';
import toast, { Toaster } from 'react-hot-toast';
import { noCase, sentenceCase } from 'change-case';
// components
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
import Iconify from '../components/iconify';
import '../main.css'
import { AccountProfile } from '../sections/account/account-profile';
import { AccountProfileDetails } from '../sections/account/account-profile-details';
import {useUserStore, supabase, useCompanyStore} from '../supabaseClient'



// mock
import PRODUCTS from '../_mock/products';


// ----------------------------------------------------------------------

export default function AccountPage() {

  const navigate = useNavigate();

  const { userData } = useUserStore(
    (state) => ({ userData: state.user })
  )

  useEffect(() => {
    if (!userData.name) {
      navigate("/login", {replace: true} );
    }
  }, []);

  return (
    <>
      <Helmet>
      <title>
        Account | Sessions HQ
      </title>
    </Helmet>
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <div>
            <Typography variant="h4">
              Account
            </Typography>
          </div>
          <div>
            <Grid
              container
              spacing={3}
              m={1}
            >
              <Grid
                xs={12}
                md={6}
                lg={4}
                p={1}
              >
                <AccountProfile />
              </Grid>
              <Grid
                xs={12}
                md={6}
                lg={7}
                p={1}
              >
                <AccountProfileDetails />
              </Grid>
            </Grid>
          </div>
        </Stack>
      </Container>
    </Box>
      
    </>
  );
}

import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// @mui
import { Container, Stack, Card, CardHeader, CardContent, Box, Grid, CardActions, Divider, Typography, Button, FormControl, InputLabel, MenuItem, TextField, FormControlLabel, Radio, Select, FormLabel, RadioGroup } from '@mui/material';
import { Modal } from 'react-responsive-modal';
import toast, { Toaster } from 'react-hot-toast';
import notifyToSlackChannel from 'react-slack-notification';
// components
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
import Iconify from '../components/iconify';
import '../main.css'
import {useUserStore, supabase} from '../supabaseClient'


// mock
import PRODUCTS from '../_mock/products';

// ----------------------------------------------------------------------

export default function HelpPage() {
  const [newSubject, setNewSubject] = useState('');
  const [newContent, setNewContent] = useState('');

  const notifySuccess = () => toast.success('Inquiry was sent successfully.');

  const navigate = useNavigate();

  const { userData } = useUserStore(
    (state) => ({ userData: state.user })
  )

  const handleSubject = (event) => {
    setNewSubject(event.target.value);
  };

  const handleContent = (event) => {
    setNewContent(event.target.value);
  };

  async function sendSlackMessage(message) {
    try {
      const { error } = await supabase
        .from('help')
        .insert({ subject: newSubject, content: newContent })

      if (!error) {
        const { error2 } = await supabase
        .from('notifications')
        .insert({ username: '@john', state: 'unseen', content: message })
        notifySuccess();
      }
      
    } catch (error) {
      console.error('Error sending message:', error.message);
    }
  }

  const handleSubmit = () => {
    sendSlackMessage(`New Help Center submission from ${userData.name} at ${userData.company}. \n\n Subject: ${newSubject} \n\n Content: ${newContent}`);
    setNewContent('');
    setNewSubject('');
  }

  useEffect(() => {
    if (!userData.name) {
      navigate("/login", {replace: true} );
    }
  }, []);

  return (
    <>
      <Helmet>
        <title> Help | Sessions HQ</title>
      </Helmet>

      <Container>
        <Toaster/>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Help Center
        </Typography>
        </Stack>

        <Card>
        <CardHeader 
          title="We usually respond within an hour..."
        /><br/>
        <CardContent sx={{ pt: 0 }}>
          <Box>
                <TextField
                  sx={{ m: '10px' }}
                  fullWidth
                  label="Subject"
                  name="subject"
                  onChange={handleSubject}
                  required
                  value={newSubject}
                />
                <TextField
                sx={{ m: '10px' }}
                  fullWidth
                  label="Content"
                  name="content"
                  onChange={handleContent}
                  required
                  multiline
                  rows={12}
                  value={newContent}
                />
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" sx={{ m: '10px' }} onClick={() => {handleSubmit()}}>
            Submit Inquiry
          </Button>
        </CardActions>
      </Card>
        
      </Container>
      
    </>
  );
}

import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid
} from '@mui/material';
import toast from 'react-hot-toast';

import {useUserStore, supabase, useCompanyStore} from '../../supabaseClient'

export const AccountProfileDetails = () => {

  const { userData } = useUserStore(
    (state) => ({ userData: state.user })
  )

  const [newRequestName, setNewRequestName] = useState(userData.name);
  const [newRequestEmail, setNewRequestEmail] = useState('');
  const [newRequestUsername, setNewRequestUsername] = useState(userData.username);

  const notifySuccessChange = () => toast.success('Information updated successfully.');

  const handleName = (event) => {
    setNewRequestName(event.target.value);
  };

  const handleEmail = (event) => {
    setNewRequestEmail(event.target.value);
  };

  const handleUsername = (event) => {
    setNewRequestUsername(event.target.value);
  };

  const submitUsername = async () => {
    const { error } = await supabase
      .from('users')
      .update({ username: newRequestUsername })
      .eq( 'email', userData.email );

  };

  const submitEmail = async () => {
    const { error } = await supabase
      .from('users')
      .update({ email: newRequestEmail })
      .eq( 'name', userData.name );

      if (!error) notifySuccessChange();

  };

  const submitName = async () => {
    const { error } = await supabase
      .from('users')
      .update({ name: newRequestName })
      .eq( 'email', userData.email );

  };

  const handleSubmit = () => {
    submitName();
    submitUsername();
    notifySuccessChange();
  }

  useEffect(() => {
    setNewRequestName(userData.name);
    setNewRequestUsername(userData.username);
  }, [])

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
    >
      <Card>
        <CardHeader 
          title="Profile"
        /><br/>
        <CardContent sx={{ pt: 0 }}>
          <Box>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  helperText="Specify your full name..."
                  label="Name"
                  name="Name"
                  onChange={handleName}
                  required
                  value={newRequestName}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  helperText="Must start with an '@' sign..."
                  label="Username"
                  name="username"
                  onChange={handleUsername}
                  required
                  value={newRequestUsername}
                />
              </Grid>
              
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button onClick={handleSubmit}  variant="contained" sx={{ m: '10px' }}>
            Save details
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-responsive-modal/styles.css';
import '../main.css'
import { Modal } from 'react-responsive-modal';
import axios from 'axios';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  TextField,
  MenuItem,
  TableBody,
  Select,
  TableCell,
  Container,
  Typography,
  FormControl,
  FormGroup,
  RadioGroup,
  FormLabel,
  Radio,
  FormControlLabel,
  InputLabel,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';

import toast, { Toaster } from 'react-hot-toast';

import { useUserStore, supabase, useRequestStore } from '../supabaseClient'
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'requester', label: 'Requester', alignRight: false },
  { id: 'requestName', label: 'Request Name', alignRight: false },
  { id: 'requestType', label: 'Request Type', alignRight: false },
  { id: 'deliveryInfo', label: 'Date Delivered', alignRight: false },
  { id: 'download', label: 'Download', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function HistoryPage() {
  const [open, setOpen] = useState(null);

  const [ID, setID] = useState(null);

  const [makeRequest, setMakeRequest] = useState(null);

  const [editRequest, setEditRequest] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('status');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [REQUESTLIST, setRequestList] = useState([]);

  const { userData } = useUserStore(
    (state) => ({ userData: state.user })
  )

  const { reqData } = useRequestStore(
    (state) => ({ reqData: state.requests })
  )
  
  const navigate = useNavigate();
  const successDownload = () => toast.success('Download has started!');

  const onOpenModal = () => setMakeRequest(true);
  const onCloseModal = () => setMakeRequest(false);

  const onOpenEditModal = () => setEditRequest(true);
  const onCloseEditModal = () => setEditRequest(false);

  async function getRequests() {
    setRequestList(reqData.filter(req => req.delivered));
  }

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = REQUESTLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const [newRequestTitle, setNewRequestTitle] = useState('');
  const [newRequestDesc, setNewRequestDesc] = useState('');
  const [newRequestFormat, setNewRequestFormat] = useState('');
  const [newRequestLinks, setNewRequestLinks] = useState('');
  const [newRequestType, setNewRequestType] = useState('Full Composition & Recording for OST');

  const handleTitle = (event) => {
    setNewRequestTitle(event.target.value);
  };

  const handleDesc = (event) => {
    setNewRequestDesc(event.target.value);
  };

  const handleLinks = (event) => {
    setNewRequestLinks(event.target.value);
  };

  const handleFormat = (event) => {
    setNewRequestFormat(event.target.value);
  };

  async function submitNewRequest() {
    const { error } = await supabase
    .from('requests')
    .insert({ content: newRequestDesc, company: userData.company, state: 'In Queue', requestName: newRequestTitle, requestType: newRequestType, username: userData.username, avatarUrl: userData.photo, format: newRequestFormat, links: newRequestLinks});

    onCloseModal();
  }

  async function submitEditRequest() {
    const { error } = await supabase
    .from('requests')
    .update({ content: newRequestDesc, requestName: newRequestTitle, requestType: newRequestType, format: newRequestFormat, links: newRequestLinks})
    .eq('id', ID);

    onCloseEditModal();
    setNewRequestTitle('');
    setNewRequestDesc('');
    setNewRequestFormat('');
    setNewRequestLinks('');
    setNewRequestType('');
    setRequestTypeModal('');
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - REQUESTLIST.length) : 0;

  const filteredUsers = applySortFilter(REQUESTLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const [requestTypeModal, setRequestTypeModal] = useState('Full Composition & Recording for OST');

  const handleChange = (event) => {
    setRequestTypeModal(event.target.value);
    setNewRequestType(event.target.value);
  };

  const deleteRequest = async (id) => {
    handleCloseMenu();
    const { error } = await supabase
      .from('requests')
      .delete()
      .eq('id', ID)
  }

  const openEditMenu = (event, id) => {
    setID(id);
    handleOpenMenu(event);
  }

  const handleDownload = (event, id) => {
    const keys = REQUESTLIST.filter(req => req.id === id)[0]?.deliveryInfo.keys;
    const tempForm = new FormData();

    keys.forEach((tempKey) => {
      axios
      .post('https://s3.sessionshq.com/', tempForm, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          type: 'DOWNLOAD',
          key: tempKey,
        },
      }
      )
      .then(async (response) => {
        window.open(response.data.url, '_blank');
        successDownload();
      })
      .catch((error) => {
        console.log(error)
      });
    })
  }

  useEffect(() => {
    getRequests();
  }, []);

  useEffect(() => {
    if (!userData.name) {
      navigate("/login", {replace: true} );
    }
  }, []);

  return (
    <>
      <Helmet>
        <title> History | Sessions HQ </title>
      </Helmet> 
    <Toaster/>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Completed History
          </Typography>
        </Stack>

        <br/><br/>

      <Modal classNames={{modal: 'modalBox'}} open={makeRequest} onClose={onCloseModal} center>
        <h2>New Request</h2>

        <TextField
          id="title-input"
          label="Title"
          fullWidth
          onChange={handleTitle}
        />

        <br/><br/>

        <TextField
          id="desc-input"
          label="Description"
          multiline
          fullWidth
          minRows={5}
          onChange={handleDesc}
        />

        <br/><br/>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Request Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="type-input"
            value={requestTypeModal}
            label="Request Type"
            onChange={handleChange}
            autoWidth
          >
            <MenuItem value={'Full Composition & Recording for OST'}>Full Composition & Recording for OST</MenuItem>
            <MenuItem value={'Sound FX'}>Sound FX</MenuItem>
            <MenuItem value={'Foley'}>Foley</MenuItem>
            <MenuItem value={'Jingle'}>Jingle</MenuItem>
            <MenuItem value={'Narration'}>Narration</MenuItem>
            <MenuItem value={'Cleanup/Editing'}>Cleanup/Editing</MenuItem>
            <MenuItem value={'Re-mix/master'}>Re-mix/master</MenuItem>
            <MenuItem value={'Overdubs'}>Overdubs</MenuItem>
            <MenuItem value={'VR/3D Audio'}>VR/3D Audio</MenuItem>
            <MenuItem value={'Other'}>Other</MenuItem>
          </Select>
        </FormControl>

        <br/><br/>

        <TextField
          id="format-input"
          label="Requested Format (ex: All individual tracks in wav format.)"
          fullWidth
          onChange={handleFormat}
        />

        <br/><br/>

        <TextField
          id="link-input"
          label="Links to resources/files/references"
          multiline
          fullWidth
          minRows={3}
          onChange={handleLinks}
        />

        <br/><br/>

        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">Queue Position</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="next"
            name="radio-buttons-group"
          >
            <FormControlLabel value="top" control={<Radio />} label="Move to top (any active request will be nullified)" />
            <FormControlLabel value="second" control={<Radio />} label="Move to second" />
            <FormControlLabel value="next" control={<Radio />} label="Move to last" />
          </RadioGroup>
        </FormControl>

        <br/><br/>

        <Button variant="outlined" color="error" fullWidth startIcon={<Iconify icon="foundation:x" />}
          onClick={() => {
            onCloseModal();
          }}>
            Cancel request
        </Button>

          <br/><br/>

        <Button variant="contained" fullWidth startIcon={<Iconify icon="mdi:check-bold" />}
          onClick={() => {
            submitNewRequest();

          }}>
            Submit request to queue
        </Button>

        

      </Modal>


      <Modal classNames={{modal: 'modalBox'}} open={editRequest} onClose={onCloseEditModal} center>
        <h2>Edit Request</h2>

        <TextField
          id="title-input"
          label="Title"
          defaultValue={REQUESTLIST.filter(req => req.id === ID)[0]?.requestName}
          fullWidth
          onChange={handleTitle}
        />

        <br/><br/>

        <TextField
          id="desc-input"
          label="Description"
          defaultValue={REQUESTLIST.filter(req => req.id === ID)[0]?.content}
          multiline
          fullWidth
          minRows={5}
          onChange={handleDesc}
        />

        <br/><br/>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Request Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="type-input"
            defaultValue={'Full Composition & Recording for OST'}
            label="Request Type"
            onChange={handleChange}
            autoWidth
          >
            <MenuItem value={'Full Composition & Recording for OST'}>Full Composition & Recording for OST</MenuItem>
            <MenuItem value={'Sound FX'}>Sound FX</MenuItem>
            <MenuItem value={'Foley'}>Foley</MenuItem>
            <MenuItem value={'Jingle'}>Jingle</MenuItem>
            <MenuItem value={'Narration'}>Narration</MenuItem>
            <MenuItem value={'Cleanup/Editing'}>Cleanup/Editing</MenuItem>
            <MenuItem value={'Re-mix/master'}>Re-mix/master</MenuItem>
            <MenuItem value={'Overdubs'}>Overdubs</MenuItem>
            <MenuItem value={'VR/3D Audio'}>VR/3D Audio</MenuItem>
            <MenuItem value={'Other'}>Other</MenuItem>
          </Select>
        </FormControl>

        <br/><br/>

        <TextField
          id="format-input"
          label="Requested Format (ex: All individual tracks in wav format.)"
          fullWidth
          defaultValue={REQUESTLIST.filter(req => req.id === ID)[0]?.format}
          onChange={handleFormat}
        />

        <br/><br/>

        <TextField
          id="link-input"
          label="Links to resources/files/references"
          defaultValue={REQUESTLIST.filter(req => req.id === ID)[0]?.links}
          multiline
          fullWidth
          minRows={3}
          onChange={handleLinks}
        />



        <br/><br/>

        <Button variant="outlined" color="error" fullWidth startIcon={<Iconify icon="foundation:x" />}
          onClick={() => {
            onCloseEditModal();
          }}>
            Discard edits
        </Button>

          <br/><br/>

        <Button variant="contained" fullWidth startIcon={<Iconify icon="mdi:check-bold" />}
          onClick={() => {
            submitEditRequest();
          }}>
            Submit edits
        </Button>

      </Modal>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }} >
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={REQUESTLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {REQUESTLIST.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, username, requestName, requestType, state, avatarUrl, createdAt } = row;
                    const selectedUser = selected.indexOf(requestName) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} selected={selectedUser}>
                        <TableCell padding="normal" />

                        <TableCell component="th" scope="row" padding="none" >
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={'User'} src={avatarUrl} />
                            <Typography variant="subtitle2" noWrap>
                              {username}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{requestName}</TableCell>
                        <TableCell align="left">{requestType}</TableCell>

                        <TableCell align="left">{new Date(createdAt).toLocaleString()}</TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => {
                              handleDownload(event, id);
                            }}>
                            <Iconify icon={'solar:download-bold'} />
                          </IconButton>
                        </TableCell>

                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        

        <MenuItem sx={{ color: 'info' }}>
          <Iconify icon={'material-symbols:download'} sx={{ mr: 2 }} />
          Download
        </MenuItem>
      </Popover>
    </>
  );
}



import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { faker } from '@faker-js/faker';
import { useEffect, useReducer, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import { useUserStore, useRequestStore, useCompanyStore, supabase } from '../supabaseClient'
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';



// ----------------------------------------------------------------------



export default function DashboardAppPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  
  const { userData } = useUserStore(
    (state) => ({ userData: state.user })
  )

  const { reqData } = useRequestStore(
    (state) => ({ reqData: state.requests })
  )

  const { companyData } = useCompanyStore(
    (state) => ({ companyData: state.company })
  )

  const findRequestBreakdown = (name) => {
    let amt = 0;
    for (let i = 0; i < reqData.length; i += 1) {
      if (reqData[i].requestType === name) {
        amt += 1;
      }
    }
    return amt;
  }

  const sortById = () => (elem1, elem2) => {
    if (elem1.position < elem2.position) {
        return -1;
    }
    if (elem1.position > elem2.position) {
        return 1;
    }
    return 0;
};

  useEffect(() => {
    setTimeout(() => {
      if (!userData.name) {
        navigate("/login", {replace: true} );
      }
    }, 0)
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | Sessions HQ </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Welcome, {userData.name}!
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Items In Queue" total={reqData?.length > 0 ? reqData?.filter(req => !req.delivered).length : 0} color="info" icon={'mdi:check-bold'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            {companyData.plan === 'ADMIN' 
            ? <AppWidgetSummary title="Items Next Up" total={reqData?.length > 0 ? reqData?.filter(req => req.state === 'Next Up').length : 0} icon={'material-symbols:person'} />
            : <AppWidgetSummary title="Total requests by user" total={reqData?.length > 0 ? reqData?.filter(req => req.username === userData.username).length : 0} icon={'material-symbols:person'} />
            }
            
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
          {companyData.plan === 'ADMIN' 
            ? <AppWidgetSummary title="Items In Progress" total={reqData?.length > 0 ? reqData?.filter(req => req.state === 'In Progress').length : 0} icon={'material-symbols:person'} />
            : <AppWidgetSummary title="Total company requests" total={reqData?.length > 0 ? reqData?.filter(req => req.company === userData.company).length : 0} color="warning" icon={'mdi:company'} />
            }
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Completed Requests" total={reqData?.length > 0 ? reqData?.filter(req => req.delivered).length : 0} color="error" icon={'material-symbols:box'} />
          </Grid>

          

          {[reqData][0].length > 1 && <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Queue Overview"
              list={reqData?.sort(sortById()).slice(0,5).map((req, index) => ({
                subheader: req.requestType,
                user: req.username,
                id: faker.datatype.uuid(),
                title: req.requestName,
                type: `order${index + 1}`,
                time: new Date(req.createdAt)
              }))}
            />
          </Grid>}

          {[reqData][0].length > 1 && <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Types of requests"
              subheader="total lifetime breakdown"
              chartData={[
                { label: 'Full Composition & Recording for OST', value: findRequestBreakdown('Full Composition & Recording for OST') },
                { label: 'Sound FX', value: findRequestBreakdown('Sound FX') },
                { label: 'Foley', value: findRequestBreakdown('Foley') },
                { label: 'Jingle', value: findRequestBreakdown('Jingle') },
                { label: 'Narration', value: findRequestBreakdown('Narration') },
                { label: 'Cleanup/Editing', value: findRequestBreakdown('Cleanup/Editing') },
                { label: 'Re-mix/master', value: findRequestBreakdown('Re-mix/master') },
                { label: 'Overdubs', value: findRequestBreakdown('Overdubs') },
                { label: 'VR/3D Audio', value: findRequestBreakdown('VR/3D Audio') },
                { label: 'Other', value: findRequestBreakdown('Other') },
              ]}
            />
          </Grid>}

          
        </Grid>
      </Container>
    </>
  );
}

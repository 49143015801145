import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, Box } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');
  const [login, setLogin] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);

  const navigate = useNavigate();

  const handleSignUp = () => {
    setLogin(!login);
  }

  return (
    <>
      <Helmet>
        <title> Login | Sessions HQ </title>
      </Helmet>

      <StyledRoot>

        {mdUp && (
          <StyledSection>
            <center><Box
              component="img"
              src="/assets/logo.png"
              sx={{ width: 270, height: 40, cursor: 'pointer'}}
            /></center>
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Sessions HQ Portal Sign-In
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              {login ? "Don’t have an account?" : "Already have an account?"} {''}
              <Link onClick={handleSignUp} variant="subtitle2">{login ? 'Get started' : 'Login'}</Link> <br/>
              <Link onClick={() => {navigate('/forgotpassword')}} display={login ? 'asdf' : 'none'} variant="subtitle2" underline="hover">
                Forgot password
              </Link>
            </Typography>

            <LoginForm isLogin={login}/>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}

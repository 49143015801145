// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;



const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_house'),
  },
  {
    title: 'queue',
    path: '/dashboard/queue',
    icon: icon('ic_list'),
  },
  {
    title: 'history',
    path: '/dashboard/history',
    icon: icon('ic_history'),
  },
  {
    title: 'team',
    path: '/dashboard/team',
    icon: icon('ic_team'),
  },
  {
    title: 'notifications',
    path: '/dashboard/notifications',
    icon: icon('ic_bell'),
  },
  {
    title: 'account',
    path: '/dashboard/user',
    icon: icon('ic_acct'),
  },
  {
    title: 'help center',
    path: '/dashboard/help',
    icon: icon('ic_help'),
  },

];

export default navConfig;

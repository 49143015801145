import Onboarding from 'react-onboarding-pro';
import { Helmet } from 'react-helmet-async';
import {useDropzone} from 'react-dropzone';
import "react-onboarding-pro/build/index.css";
import "../main.css"
import { useEffect } from 'react';
import { supabase } from '../supabaseClient';


const submit = async (e) => {
    const { error } = await supabase
        .from('users')
        .update({ username: e.username })
        .eq('email', (await supabase.auth.getUser()).data.user.email)
}

export default function OnboardingPage() {

    const config = {
        steps: [
          {
            title: 'Welcome to the Sessions HQ Dashboard',
            description: 'We genuinely thank you for working with us.'
          },
          {
            title: "Let's get you setup!",
            description: 'This will take 10 seconds.',
            type: 'form', 
            fields: [
              {
                label: 'Username (must include @)',
                name: 'username',
                type: 'text',
                placeholder: '@john',
                validation: '[@]'
              },
            ],
            onSubmit: submit
          },
          {
            title: "Let's get a profile picture for you!",
            description: 'Keep it less than 2MB please, use an online image compressor if needed.',
            type: 'component', 
            component: Upload,
            props: {
                test: 1
            }
          },
          {
            title: "Great! Now a short tutorial.",
            description: 'This will take less than 60 seconds & show you everything you need.',
          },
          {
            title: "When you first join, you will be on the Dashboard page.",
            description: 'In the top right, is your account info & the logout option along with your notifications.',
            type: 'component', 
            component: GifPlayer1
          },

          {
            title: "You will also see stats, project type breakdown, & your queue overview.",
            description: 'The dashboard is your overview of your entire account.',
            type: 'component', 
            component: GifPlayer2
          },

          {
            title: "Next is the Queue page. This is the most important one.",
            description: 'Here you can make new requests, reorder them by dragging/dropping, & see more details/leave comments/edit the request.',
            type: 'component', 
            component: GifPlayer3
          },

          {
            title: "Now to the History page.",
            description: 'You can think of this as your completed queue log. As requests are completed, they will be moved here for you to download.',
            type: 'component', 
            component: GifPlayer4
          },

          {
            title: "Next, let's go to the Team page!",
            description: 'Here you can see members of your team, delete members of the team, or add new team members.',
            type: 'component', 
            component: GifPlayer5
          },

          {
            title: "Next, is the Account page.",
            description: 'From the Account page, you can update your name, username, profile pic, & other information.',
            type: 'component', 
            component: GifPlayer6
          },

          {
            title: "Now to the Help Center.",
            description: 'If you ever have any questions this is where you can go, we will respond ASAP every single time.',
            type: 'component', 
            component: GifPlayer7
          },

          {
            title: "Last but not least...",
            description: 'In the bottom left, you can always get easy access to our legal documents & also submit any bugs/feature requests.',
            type: 'component', 
            component: GifPlayer8
          },
          {
            title: "Great! That's all for now.",
            description: 'Again, we thank you for choosing Sessions HQ. You will now have full access.',
          },
                      
        ],
        overlayClose: false
      };

    Onboarding(config);

    return (
        <>
        <Helmet>
            <title>
                Onboarding | Sessions HQ
            </title>
        </Helmet>
        </>
    )
}

const Upload = ({ disable, setButtonState, ...props }) => {
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
          {file.path} -  {(file.size / 10**6).toFixed(2)} MB
        </li>
      ));
    
    useEffect(() => {
        const handleUpload = async () => {
            if (acceptedFiles.length > 0) {
                const randString = Math.random().toString(36).slice(2, 7);
                const { data, error } = await supabase
                    .storage
                    .from('photos')
                    .upload(`${randString}-${acceptedFiles[0].name}`, acceptedFiles[0], {
                        cacheControl: '3600',
                        upsert: false
                    })
                
                const pfpURL = `https://ynobcgalfvsdrpkcvwyp.supabase.co/storage/v1/object/public/photos/${randString}-${acceptedFiles[0].name}`

                const { errorPhoto } = await supabase
                    .from('users')
                    .update({ photo: pfpURL })
                    .eq('email', (await supabase.auth.getUser()).data.user.email)

                setButtonState(!disable);
            } 
        }
        handleUpload();
    }, [acceptedFiles])

    return (
        <>
        <div>
            <br/>
        <section className="container">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className='fileDrop'>
              Drag 'n' drop a photo here, or click to select files (PNG or JPEG/JPG only!)
              {files.length > 0 ? <><br/> <h4>{files}</h4></> : null}
            </div>
          </div>
        </section>
        </div>
        </>
    )
}

const GifPlayer1 = ({ disable, setButtonState }) => {
    useEffect(() => {
        setButtonState(!disable);
    }, [])

    return (
        <div className='videoOnboarding'>
            <video width="60%" height="60%" autoPlay loop>
                <source src='https://ynobcgalfvsdrpkcvwyp.supabase.co/storage/v1/object/public/photos/9.mp4?t=2023-08-25T00%3A50%3A23.948Z' type="video/mp4" />
                <track kind='captions' />
            </video>
        </div>
      );
}

const GifPlayer2 = ({ disable, setButtonState }) => {
    useEffect(() => {
        setButtonState(!disable);
    }, [])

    return (
        <div className='videoOnboarding'>
            <video width="60%" height="60%" autoPlay loop>
                <source src='https://ynobcgalfvsdrpkcvwyp.supabase.co/storage/v1/object/public/photos/1.mp4?t=2023-08-24T07%3A18%3A26.759Z' type="video/mp4" />
                <track kind='captions' />
            </video>
        </div>
      );
}

const GifPlayer3 = ({ disable, setButtonState }) => {
    useEffect(() => {
        setButtonState(!disable);
    }, [])

    return (
        <div className='videoOnboarding'>
            <video width="60%" height="60%" autoPlay loop>
                <source src='https://ynobcgalfvsdrpkcvwyp.supabase.co/storage/v1/object/public/photos/2.mp4?t=2023-08-24T07%3A19%3A03.130Z' type="video/mp4" />
                <track kind='captions' />
            </video>
        </div>
      );
}

const GifPlayer4 = ({ disable, setButtonState }) => {
    useEffect(() => {
        setButtonState(!disable);
    }, [])

    return (
        <div className='videoOnboarding'>
            <video width="60%" height="60%" autoPlay loop>
                <source src='https://ynobcgalfvsdrpkcvwyp.supabase.co/storage/v1/object/public/photos/3.mp4?t=2023-08-24T07%3A19%3A34.269Z' type="video/mp4" />
                <track kind='captions' />
            </video>
        </div>
      );
}

const GifPlayer5 = ({ disable, setButtonState }) => {
    useEffect(() => {
        setButtonState(!disable);
    }, [])

    return (
        <div className='videoOnboarding'>
            <video width="60%" height="60%" autoPlay loop>
                <source src='https://ynobcgalfvsdrpkcvwyp.supabase.co/storage/v1/object/public/photos/4.mp4?t=2023-08-24T07%3A19%3A59.259Z' type="video/mp4" />
                <track kind='captions' />
            </video>
        </div>
      );
}

const GifPlayer6 = ({ disable, setButtonState }) => {
    useEffect(() => {
        setButtonState(!disable);
    }, [])

    return (
        <div className='videoOnboarding'>
            <video width="60%" height="60%" autoPlay loop>
                <source src='https://ynobcgalfvsdrpkcvwyp.supabase.co/storage/v1/object/public/photos/5.mp4?t=2023-08-24T07%3A20%3A09.881Z' type="video/mp4" />
                <track kind='captions' />
            </video>
        </div>
      );
}

const GifPlayer7 = ({ disable, setButtonState }) => {
    useEffect(() => {
        setButtonState(!disable);
    }, [])

    return (
        <div className='videoOnboarding'>
            <video width="60%" height="60%" autoPlay loop>
                <source src='https://ynobcgalfvsdrpkcvwyp.supabase.co/storage/v1/object/public/photos/6.mp4?t=2023-08-24T07%3A20%3A38.975Z' type="video/mp4" />
                <track kind='captions' />
            </video>
        </div>
      );
}

const GifPlayer8 = ({ disable, setButtonState }) => {
    useEffect(() => {
        setButtonState(!disable);
    }, [])

    return (
        <div className='videoOnboarding'>
            <video width="60%" height="60%" autoPlay loop>
                <source src='https://ynobcgalfvsdrpkcvwyp.supabase.co/storage/v1/object/public/photos/7.mp4?t=2023-08-24T07%3A20%3A48.543Z' type="video/mp4" />
                <track kind='captions' />
            </video>
        </div>
      );
}

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import { Grid } from '@mui/material';
import ShopProductCard from './ProductCard';
import ShopProductCard2 from './ProductCard2';
import {supabase, useCompanyStore, useUserStore, useAdminCompanyStore} from '../../../supabaseClient';


// ----------------------------------------------------------------------


export default function ProductList({ ...other }) {
  const [teamList, setTeamList] = useState([{}])

  const { companyData } = useCompanyStore(
    (state) => ({ companyData: state.company })
  )
  
  const { adminCompany } = useAdminCompanyStore(
    (state) => ({ adminCompany: state.adminCompany })
  )

  const getTeam = async () => {
    if (companyData.plan !== 'ADMIN') {
      setTeamList(companyData.team?.teammates);
    }
    else {
      setTeamList(adminCompany);
    }
  }

  useEffect(() => {
     getTeam();
  }, [])

  return (
    <Grid container spacing={3} {...other}>
      {teamList?.map((member, index) => (
        <Grid key={index} item xs={12} sm={6} md={3}>
          {companyData.plan === 'ADMIN' ? <ShopProductCard2 product={member}/> : <ShopProductCard product={member}/>}
        </Grid>
      ))}
    </Grid>
  );
}

import { Helmet } from 'react-helmet-async';
import {useDropzone} from 'react-dropzone';
import ByteConverter from "byte-converter-react";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-responsive-modal/styles.css';
import '../main.css'
import { Modal } from 'react-responsive-modal';
import { ReactionBarSelector, ReactionCounter } from '@charkour/react-reactions';
import axios from 'axios';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  TableHead,
  TableRow,
  TextField,
  MenuItem,
  TableBody,
  CardContent,
  Select,
  TableCell,
  Container,
  Typography,
  FormControl,
  RadioGroup,
  FormLabel,
  Radio,
  FormControlLabel,
  Divider,
  InputLabel,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import toast, { Toaster } from 'react-hot-toast';
import { fToNow } from '../utils/formatTime';
import { useUserStore, supabase, useRequestStore, useCommentStore, useCompanyStore } from '../supabaseClient'
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';



// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'requester', label: 'Requester', alignRight: false },
  { id: 'requestName', label: 'Request Name', alignRight: false },
  { id: 'requestType', label: 'Request Type', alignRight: false },
  { id: 'createdAt', label: 'Date Created', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function RequestPage() {
  const [open, setOpen] = useState(null);

  const [openWarning, setOpenWarning] = useState(null);

  const [ID, setID] = useState(0);

  const [commentID, setCommentID] = useState(0);

  const [makeRequest, setMakeRequest] = useState(null);

  const [lookRequest, setLookRequest] = useState(null);

  const [cancelReq, setCancelReq] = useState(null);

  const [uploadingNow, setUploadingNow] = useState(null);

  const [uploadingAmt, setUploadingAmt] = useState(0);

  const [readySubmission, setReadySubmission] = useState(null);

  const [submissionComment, setSubmissionComment] = useState('');

  const [REQUESTLIST, setRequestList] = useState([]);

  const [newComment, setNewComment] = useState('');
  
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

  const [radioValue, setRadioValue] = useState(null);

  const [revisionModal, setRevisionModal] = useState(null);

  const [revisionComment, setRevisionComment] = useState('');

  const [editName, setEditName] = useState('');
  const [editType, setEditType] = useState('');
  const [editDesc, setEditDesc] = useState('');
  const [editFormat, setEditFormat] = useState('');

  const [editing, setEditing] = useState(null);
  const [replying, setReplying] = useState(null);

  const notifySuccessDelete = () => toast.success('Deletion was successful.');
  const notifySuccessEdit = () => toast.success('Edit was successful.');
  const notifySuccessCreate = () => toast.success('Request created successfully.');
  const notifySuccessComplete = () => toast.success('Request marked as complete. Moved to History page.');
  const notifySuccessRevision = () => toast.success('Request was sent back for revision.');
  const notifySuccessSubmit = () => toast.success('Request submitted for review.');
  const notifyS3Error = () => toast.error('Error uploading to S3.');
  const successDownload = () => toast.success('Download has started!');

  const sortById = () => (elem1, elem2) => {
    if (elem1.position < elem2.position) {
        return -1;
    }
    if (elem1.position > elem2.position) {
        return 1;
    }
    return 0;
};

  const { userData } = useUserStore(
    (state) => ({ userData: state.user })
  )

  const { reqData } = useRequestStore(
    (state) => ({ reqData: state.requests })
  )

  const { companyData } = useCompanyStore(
    (state) => ({ companyData: state.company })
  )

  const { commentData } = useCommentStore(
    (state) => ({ commentData: state.comments })
  )

  const navigate = useNavigate();


  const onOpenModal = () => setMakeRequest(true);
  const onCloseModal = () => {setMakeRequest(false); setEditing(null); setReplying(null);}

  const onOpenLookRequest = () => setLookRequest(true);
  const onCloseLookRequest = () => {setLookRequest(false); setEditing(null); setReplying(null);}

  async function getRequests() {
    if (companyData.plan !== 'ADMIN') {
      // const tempList = reqData.filter(req => !req.delivered).sort(sortById());
      // orderHelper(tempList);
      
      setRequestList(reqData.filter(req => !req?.delivered).sort(sortById()));
      
    }
    else {
      // const tempList = reqData.filter(req => !req.delivered && req.state !== 'In Queue').sort(sortById());
      // orderHelper(tempList);
      setRequestList(reqData.filter(req => !req.delivered && req.state !== 'In Queue').sort(sortById()));
    }
  }

  const handleOpenWarningMenu = () => {
    setOpenWarning(true);
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseEditMenu = () => {
    setOpenWarning(null);
    setEditing(null);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setEditing(null);
  };

  const startEdit = () => {
    setEditName(REQUESTLIST.filter((item) => item.id === ID)[0]?.requestName);
    setEditDesc(REQUESTLIST.filter((item) => item.id === ID)[0]?.content);
    setEditType(REQUESTLIST.filter((item) => item.id === ID)[0]?.requestType);
    setEditFormat(REQUESTLIST.filter((item) => item.id === ID)[0]?.format);
    setEditing(true);
  }

  const saveEdit = async () => {
    const { error } = await supabase
    .from('requests')
    .update({ content: editDesc, requestName: editName, requestType: editType, format: editFormat })
    .eq('id', ID);

    if (!error) {
      notifySuccessEdit();
      setEditing(null);
    }
  }

  const handleReply = (event, id) => {
    setReplying(true);
    setCommentID(id);
  }

  const sendReply = async () => {
    let repliesList = commentData?.filter((comment) => comment.id === commentID)[0].replies;
    if (!repliesList) repliesList = [];
    repliesList.push({content: newComment, username: userData.username, photo: userData.photo, company: userData.company, requestID: ID, createdAt: new Date().toISOString()});

    const { error } = await supabase
    .from('comments')
    .update({ replies: repliesList })
    .eq('id', commentID);

    if (!error && commentData?.filter((comment) => comment.requestID === ID)[0].username !== userData.username) {
      const { error2 } = await supabase
        .from('notifications')
        .insert({ username: commentData?.filter((comment) => comment.requestID === ID)[0].username, state: 'unseen', content: `${userData.username} replied to your post!` });
    }

    setNewComment('');
    setReplying(null);
  }

  const handleRadio = (e) => {
    setRadioValue(e.target.value)
  }

  const [newRequestTitle, setNewRequestTitle] = useState('');
  const [newRequestDesc, setNewRequestDesc] = useState('');
  const [newRequestFormat, setNewRequestFormat] = useState('');
  const [newRequestLinks, setNewRequestLinks] = useState('');
  const [newRequestType, setNewRequestType] = useState('Full Composition & Recording for OST');

  const handleTitle = (event) => {
    setNewRequestTitle(event.target.value);
  };

  const handleDesc = (event) => {
    setNewRequestDesc(event.target.value);
  };

  const handleLinks = (event) => {
    setNewRequestLinks(event.target.value);
  };

  const handleFormat = (event) => {
    setNewRequestFormat(event.target.value);
  };

  const handleSubmissionChange = (event) => {
    setSubmissionComment(event.target.value);
  };

  const handleActualSubmission = async () => {
  const tempKeys = { keys: [], comment: submissionComment };
  const formData = new FormData();

  acceptedFiles.forEach((file) => {
    const randString = Math.random().toString(36).slice(2, 7);
    const randKey = `${randString}-${file.name}`;
    formData.delete('file');
    formData.append('file', file);
    tempKeys.keys.push(randKey);

    // Create a CancelToken source to cancel the request if needed
    const source = axios.CancelToken.source();
    setUploadingNow(true);
    setUploadingAmt(0);
    axios
      .post('https://s3.sessionshq.com/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          type: 'UPLOAD',
          key: randKey,
        },
        onUploadProgress: (progressEvent) => {
          // Calculate the upload progress percentage
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          console.log(`Uploading ${file.name}: ${progress.toFixed(2)}%`);
          setUploadingAmt(progress.toFixed(2));
        },
        cancelToken: source.token, // Attach the CancelToken to the request
      })
      .then(async (response) => {
        console.log(response);
        const { error } = await supabase
          .from('requests')
          .update({ state: 'Awaiting Approval', deliveryInfo: JSON.stringify(tempKeys) })
          .eq('id', ID);

        setSubmissionComment('');
        acceptedFiles.length = 0;
        setReadySubmission(null);
        if (!error) notifySuccessSubmit();
        setUploadingNow(null);
        setUploadingAmt(0);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Handle cancellation (if needed)
          console.log(`Upload of ${file.name} canceled.`);
        } else {
          notifyS3Error();
          setUploadingNow(null);
          setUploadingAmt(0);
        }
      });

    // You can cancel the upload request using source.cancel() if needed
    // For example, to cancel uploads on some user action or condition
    // source.cancel("Upload canceled by user");
  });
};


  async function submitNewRequest() {
    const tempKeys = {keys: []};
    const formData = new FormData();

    if (acceptedFiles.length > 0) {
    acceptedFiles.forEach(file => {
    const randString = Math.random().toString(36).slice(2, 7);
    const randKey = `${randString}-${file.name}`;
    formData.delete('file');
    formData.append('file', file);
    tempKeys.keys.push(randKey);

    // Create a CancelToken source to cancel the request if needed
    const source = axios.CancelToken.source();
    setUploadingNow(true);
    setUploadingAmt(0);
    axios
      .post('https://s3.sessionshq.com/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          type: 'UPLOAD',
          key: randKey,
        },
        onUploadProgress: (progressEvent) => {
          // Calculate the upload progress percentage
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          console.log(`Uploading ${file.name}: ${progress.toFixed(2)}%`);
          setUploadingAmt(progress.toFixed(2));
        },
        cancelToken: source.token, // Attach the CancelToken to the request
      })
      .then(async (response) => {
        if (REQUESTLIST.length === 0) {
          const { error } = await supabase
          .from('requests')
          .insert({ content: newRequestDesc, position: reqData.length, company: userData.company, state: 'Next Up', requestName: newRequestTitle, requestType: newRequestType, username: userData.username, avatarUrl: userData.photo, format: newRequestFormat, links: JSON.stringify(tempKeys)});

          if (!error) notifySuccessCreate();
        }
        else {
          const { error } = await supabase
          .from('requests')
          .insert({ content: newRequestDesc, position: reqData.length, company: userData.company, state: 'In Queue', requestName: newRequestTitle, requestType: newRequestType, username: userData.username, avatarUrl: userData.photo, format: newRequestFormat, links: JSON.stringify(tempKeys)});

          if (!error) notifySuccessCreate();
        }
          acceptedFiles.length = 0;
          setUploadingNow(null);
          setUploadingAmt(0);
          onCloseModal();
          
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Handle cancellation (if needed)
          console.log(`Upload of ${file.name} canceled.`);
        } else {
          notifyS3Error();
          setUploadingNow(null);
          setUploadingAmt(0);
        }
      });

    // You can cancel the upload request using source.cancel() if needed
    // For example, to cancel uploads on some user action or condition
    // source.cancel("Upload canceled by user");
  })}
  else {
    if (REQUESTLIST.length === 0) {
      const { error } = await supabase
      .from('requests')
      .insert({ content: newRequestDesc, position: reqData.length, company: userData.company, state: 'Next Up', requestName: newRequestTitle, requestType: newRequestType, username: userData.username, avatarUrl: userData.photo, format: newRequestFormat, links: JSON.stringify(tempKeys)});

      if (!error) notifySuccessCreate();
    }
    else {
      const { error } = await supabase
      .from('requests')
      .insert({ content: newRequestDesc, position: reqData.length, company: userData.company, state: 'In Queue', requestName: newRequestTitle, requestType: newRequestType, username: userData.username, avatarUrl: userData.photo, format: newRequestFormat, links: JSON.stringify(tempKeys)});

      if (!error) notifySuccessCreate();
    }
      acceptedFiles.length = 0;
      setUploadingNow(null);
      setUploadingAmt(0);
      onCloseModal();
  };
}

  const [requestTypeModal, setRequestTypeModal] = useState('Full Composition & Recording for OST');

  const handleChange = (event) => {
    setRequestTypeModal(event.target.value);
    setNewRequestType(event.target.value);
  };

  const deleteRequest = async () => {
    handleOpenWarningMenu();
    console.log(ID)
  }

  const handleActualDelete = async () => {
    const { error } = await supabase
      .from('requests')
      .delete()
      .eq('id', ID)

      handleCloseEditMenu();
      onCloseLookRequest();
      if (!error) notifySuccessDelete();
  }

  const openEditMenu = (event) => {
    handleOpenMenu(event);
  } 

  const openRequestDetails = (event, id) => {
    setID(id);
    onOpenLookRequest();
  }

  const handleComment = (event) => {
    setNewComment(event.target.value);
  }

  const makeNewComment = async () => {
    const { error } = await supabase
    .from('comments')
    .insert({ content: newComment, username: userData.username, photo: userData.photo, company: userData.company, requestID: ID });

    setNewComment('');
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "gray" : "white",
    width: 'auto',
    ...draggableStyle
  });

  const getItemStyle2 = (isDragging, draggableStyle) => ({
    background: "#fff6b3",
    ...draggableStyle
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const movedItems = reorder(
      REQUESTLIST,
      result.source.index,
      result.destination.index
    );

    movedItems.forEach(async (req, i) => {
      req.position = i;
      if (req.state !== 'In Progress') req.state = 'In Queue';
      if (req.state !== 'In Progress' && req.id === (movedItems?.filter((item) => item.state === 'In Progress').length === 0 ? movedItems[0].id : movedItems[1].id)) req.state = 'Next Up';

      const { error } = await supabase
        .from('requests')
        .update({ state: req.state, position: req.position })
        .eq('id', req.id);
    });

    setRequestList(movedItems);
  };

  const orderHelper = (list) => {

    if(list.filter((item) => item.state === 'Next Up').length === 0) {

      list.forEach(async (req, i) => {
        req.position = i;
        if (req.state !== 'In Progress') req.state = 'In Queue';
        // if (req.state !== 'In Progress' && req.id === (list?.filter((item) => item.state === 'In Progress').length === 0 ? list[0].id : list[1].id)) req.state = 'Next Up';
  
        const { error } = await supabase
          .from('requests')
          .update({ state: req.state, position: req.position })
          .eq('id', req.id);
      });

      console.log(REQUESTLIST)
      return list;
    }

    
    return list;

  }

  const markInProgress = async () => {
    const { error } = await supabase
    .from('requests')
    .update({ state: 'In Progress' })
    .eq('id', ID);
  }

  const markRequestCompleted = async () => {
    const { error } = await supabase
    .from('requests')
    .update({ delivered: true })
    .eq('id', ID);

    if (!error) {
      setLookRequest(null);
      notifySuccessComplete();
    } 
  }

  const markRequestRevision = async () => {
    const { error } = await supabase
    .from('requests')
    .update({ state: 'In Progress', position: 1, deliveryInfo: JSON.stringify({comment: revisionComment}) })
    .eq('id', ID);

    if (!error) {
      setLookRequest(null);
      notifySuccessRevision();
    } 
    
  }

  const handleCancelActive = async () => {
    const { error } = await supabase
    .from('requests')
    .update({ state: 'In Queue' })
    .eq('id', ID);

    if (!error) {
      notifySuccessRevision();
    } 
  }

  const handleDownload = () => {
    const keys = REQUESTLIST.filter(req => req.id === ID)[0]?.deliveryInfo.keys;
    const tempForm = new FormData();

    keys.forEach((tempKey) => {
      axios
      .post('https://s3.sessionshq.com/', tempForm, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          type: 'DOWNLOAD',
          key: tempKey,
        },
      }
      )
      .then(async (response) => {
        window.open(response.data.url, '_blank');
        successDownload();
      })
      .catch((error) => {
        console.log(error)
      });
    })
  }


  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} -  {(file.size / 10**6).toFixed(2)} MB
    </li>
  ));

  const getListStyle = isDraggingOver => ({
    padding: 'none',
    width: 'auto',
  });

  useEffect(() => {
    getRequests();
  }, [reqData]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     orderHelper(reqData)
  //   }, 2500)
  // }, [])

  useEffect(() => {
    if (!userData.name) {
      navigate("/login", {replace: true} );
    }
  }, []);

  return (
    <>
      
      <Helmet>
        <title> Queue | Sessions HQ </title>
      </Helmet> 

    <Container>
      <Toaster/>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Queue
          </Typography>
          {/* <Typography variant="secondary" gutterBottom>
            (In Queue = Waiting to be processed || Next Up = Next in line, but not started yet || In Progress = Actively working on it)
          </Typography> */}
          {companyData.plan !== 'ADMIN' ?
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={() => {
            onOpenModal();
          }}>
            New Request
          </Button> : null }
        </Stack>

          <Typography variant="h5" gutterBottom>
          Next Up: {REQUESTLIST?.length === 0 ? 'None' : (REQUESTLIST?.filter((item) => item.state === 'In Progress').length === 0 ? REQUESTLIST[0]?.requestName : REQUESTLIST[1]?.requestName)}
          </Typography>

          <Typography variant="h5" gutterBottom>
            In Progress: {REQUESTLIST?.filter((item) => item.state === ('In Progress' || 'Awaiting Approval'))?.length === 0 ? 'None' : ''}
          </Typography>

        {REQUESTLIST?.filter((item) => item.state === 'In Progress')?.length > 0 && REQUESTLIST?.filter((item) => item.state === 'In Progress')?.map((req) => {
          return (
          <Card key={req.id}>
          <CardContent>
          <Typography variant="h6" gutterBottom>
            Request Name: {req.requestName}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Request Type: {req.requestType}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Request Status: {req.state}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={'User'} src={req.avatarUrl} />
            <Typography variant="subtitle2" noWrap>
              {req.username}
            </Typography>
            <Button variant="contained" color={'warning'} startIcon={<Iconify icon="material-symbols:cancel" />}
            onClick={() => {
                setID(req.id)
                setCancelReq(true);
            }}>
              Cancel Request
            </Button>
            <Button variant="contained" color={'secondary'} startIcon={<Iconify icon="ph:dots-three-outline-vertical-fill" />}
            onClick={() => {
                setID(req.id)
                onOpenLookRequest()
            }}>
              View Details
            </Button>
          </Stack>
          </CardContent>
        </Card>
        )})}

      <div className='mainModalDiv'>
      <Modal classNames={{modal: 'modalBox'}} open={lookRequest} onClose={onCloseLookRequest} center>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Button variant="contained" size={'small'} color={editing ? 'success' : 'primary'} startIcon={editing ? <Iconify icon="tabler:check" /> : <Iconify icon="iconamoon:edit-fill" /> }
        onClick={() => {
          if (!editing) startEdit()
          else saveEdit()
        }}>
          {editing ? 'Save' : 'Edit'}
        </Button>
        <Button variant="contained" color={'error'} size={'small'} startIcon={<Iconify icon="mdi:trash" /> }
        onClick={deleteRequest}>
          Delete
        </Button>
        {companyData.plan === 'ADMIN' ? <Button variant="contained" sx={{ display: REQUESTLIST?.filter((item) => item.id === ID)[0]?.state === 'Awaiting Approval' ? 'none' : ''}} color={'info'} size={'small'} startIcon={REQUESTLIST?.filter((item) => item.id === ID)[0]?.state === 'Next Up' ? <Iconify icon="eos-icons:hourglass" /> :  <Iconify icon="solar:upload-bold" />}
        onClick={() => {
          if (REQUESTLIST?.filter((item) => item.id === ID)[0]?.state === 'Next Up') {
            markInProgress();
          } else {
            setReadySubmission(true);
          }}}>
          {REQUESTLIST?.filter((item) => item.id === ID)[0]?.state === 'Next Up' ? 'Mark In Progress' : 'Submit Request'}
        </Button> : null }
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {editing ? <TextField
          id="reqName"
          label="Edit name..."
          value={editName}
          onChange={(event) => {setEditName(event.target.value)}}
          sx={{margin: '5px', marginTop: '25px'}}
        /> : <h2> {REQUESTLIST?.filter((item) => item.id === ID)[0]?.requestName ? REQUESTLIST.filter((item) => item.id === ID)[0].requestName : ''} </h2>}
          <h5 className='alignRightText'>{REQUESTLIST.filter((item) => item.id === ID)[0]?.createdAt ? new Date(REQUESTLIST.filter((item) => item.id === ID)[0].createdAt).toLocaleDateString() : ''}</h5>
        </Stack>
        {editing ? <TextField
          fullWidth
          id="reqDesc"
          label="Edit description..."
          value={editDesc}
          onChange={(event) => {setEditDesc(event.target.value)}}
          sx={{margin: '5px'}}
        /> : <h3>{REQUESTLIST?.filter((item) => item.id === ID)[0]?.content ? REQUESTLIST.filter((item) => item.id === ID)[0].content : ''}</h3>}
        {editing ? <FormControl fullWidth sx={{margin: '5px'}}>
          <Select
            labelId="demo-simple-select-label"
            id="type-input"
            value={editType}
            label="Edit type..."
            onChange={(event) => {setEditType(event.target.value)}}
            
          >
            <MenuItem value={'Full Composition & Recording for OST'}>Full Composition & Recording for OST</MenuItem>
            <MenuItem value={'Sound FX'}>Sound FX</MenuItem>
            <MenuItem value={'Foley'}>Foley</MenuItem>
            <MenuItem value={'Jingle'}>Jingle</MenuItem>
            <MenuItem value={'Narration'}>Narration</MenuItem>
            <MenuItem value={'Cleanup/Editing'}>Cleanup/Editing</MenuItem>
            <MenuItem value={'Re-mix/master'}>Re-mix/master</MenuItem>
            <MenuItem value={'Overdubs'}>Overdubs</MenuItem>
            <MenuItem value={'VR/3D Audio'}>VR/3D Audio</MenuItem>
            <MenuItem value={'Other'}>Other</MenuItem>
          </Select>
        </FormControl> : <h4>{REQUESTLIST?.filter((item) => item.id === ID)[0]?.requestType ? REQUESTLIST.filter((item) => item.id === ID)[0].requestType : ''}</h4>}
        {editing ? <TextField
          fullWidth
          id="reqFormat"
          label="Edit format..."
          value={editFormat}
          onChange={(event) => {setEditFormat(event.target.value)}}
          sx={{margin: '5px'}}
        /> : <h5>{REQUESTLIST?.filter((item) => item.id === ID)[0]?.format ? REQUESTLIST.filter((item) => item.id === ID)[0].format : ''}</h5>}

          
        {companyData.plan === 'ADMIN'  ? <Button variant="contained" color={'info'} startIcon={<Iconify icon="material-symbols:download" />}
            onClick={() => {
                setRevisionModal(true);
            }}>
              {REQUESTLIST?.filter((item) => item.id === ID)[0]?.links}
            </Button> : null}
          
            <br/><br/>
        <Divider/>
        {REQUESTLIST?.filter((item) => item.id === ID)[0]?.state === 'Awaiting Approval' && companyData.plan !== "ADMIN"
        ? <Card>
          <Stack direction="column" sx={{m: '15px'}} alignItems="center" spacing={2}>
            <Typography variant='h4'>You have a new pending request submission!</Typography>
            <Typography sx={{ display: "flex", textAlign: 'center' }} variant='secondary'>Comments from Sessions HQ: {(REQUESTLIST?.filter((item) => item.id === ID)[0]?.deliveryInfo?.comment)}</Typography>
            <Button variant="contained" color={'success'} startIcon={<Iconify icon="material-symbols:download" />}
            onClick={() => {
                handleDownload();
            }}>
              Download Submission
            </Button>
            <Stack direction="row" sx={{m: '15px'}} alignItems="center" spacing={2}>
            <Button variant="contained" color={'secondary'} startIcon={<Iconify icon="mdi:approve" />}
            onClick={() => {
                markRequestCompleted();
            }}>
              Approve Submission
            </Button>
            <Button variant="contained" color={'warning'} startIcon={<Iconify icon="bx:revision" />}
            onClick={() => {
                setRevisionModal(true);
            }}>
              Request Revision
            </Button>
            </Stack>
            </Stack>
        </Card>
        : null
        }
        <h3>Comments</h3>


        {commentData?.filter((comment) => comment.requestID === ID).map((comment) => {
          return (
            <>
            <div>
              
              <Card key={comment.id} className='commentBox1'>
                  <CardContent>
                  <Stack direction="row" alignItems="center">
                      <Avatar className='commentPhoto' alt={'User'} src={comment.photo} />
                      <Stack direction="column" alignItems="left" justifyContent="space-between">
                        <Typography className='commentUsername' px={1} color="textSecondary" fontSize='12px' variant='subtitle1' gutterBottom> {comment.username}</Typography>
                        <Typography className='commentDate' px={1} color="textSecondary" fontSize='10px' variant='secondary' >{fToNow(comment.createdAt)}</Typography> 
                      </Stack>
                      <Typography className='commentContent' fontSize='14px' px={2}>{comment.content}</Typography>
                      <IconButton className='replyButton' size="large" color="inherit" onClick={(e) => {
                        handleReply(e, comment.id);
                      }}>
                        <Iconify icon={'material-symbols:reply'} />
                    </IconButton>
                  </Stack>

                  {comment?.replies?.map((commentReply) => {
                    return (
                      <>
                      <div>
                        
                        <Card key={commentReply.id} className='commentBoxReply'>
                            <CardContent>
                            <Stack direction="row" alignItems="center">
                                <Avatar className='commentPhoto' alt={'User'} src={commentReply.photo} />
                                <Stack direction="column" alignItems="left" justifyContent="space-between">
                                  <Typography className='commentUsername' px={1} fontSize='12px' color="textSecondary" variant='subtitle1' gutterBottom> {commentReply.username}</Typography>
                                  <Typography className='commentDate' px={1} fontSize='10px' color="textSecondary" variant='h7' gutterBottom>{fToNow(commentReply.createdAt)}</Typography> 
                                </Stack>
                                <Typography className='commentContent' fontSize='14px' px={2}>{commentReply.content}</Typography>
                                
                            </Stack>
                            
                            </CardContent>
                        </Card> <br/> 
                        </div> </>
                  )})}
                  
                  </CardContent>
              </Card> <br/> 
              </div> </>
        )})}
        
        <br/>
        <Stack direction="row" alignItems="center" justifyContent="space-between" >

        <TextField
          id="newComment-input"
          label={replying ? `Send a reply...` : 'Leave a comment...'}
          fullWidth
          value={newComment}
          onChange={handleComment}
          sx={{ marginRight: '10px' }}
        />

        <IconButton size="large" sx={{mr: 1, display: replying ? '' : 'none'}} disabled={!replying} color="inherit" onClick={() => {
            setReplying(null);
          }}>
            <Iconify icon={'ph:x-bold'} />
        </IconButton>

        <Button variant="outlined" size={'large'} startIcon={replying ? <Iconify icon='material-symbols:reply' /> : <Iconify icon="iconamoon:edit-fill" /> }
        onClick={(e) => {
          if (!replying) {
            makeNewComment();
          }
          else {
            sendReply();
          }
        }}>
          {replying ? 'Reply' : 'Send'}
        </Button>
        </Stack>
      </Modal>
      </div>

      <Modal classNames={{modal: 'modalBox'}} open={readySubmission} onClose={() => {setReadySubmission(null)}} center>
      <h2>Request Submission</h2>

      <TextField
        id="comments-input"
        label="Comments"
        fullWidth
        multiline
        minRows={10}
        onChange={handleSubmissionChange}
      />

      <br/><br/>

      <section className="container">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className='fileDrop'>
              Drag 'n' drop some files here, or click to select files
              {files.length > 0 ? <><br/> <h4>{files}</h4></> : null}
              </div>
            
          </div>
          
        </section>
      
        <br/><br/>

        <Button variant="contained" color="info" fullWidth startIcon={uploadingNow ? <Iconify icon="line-md:loading-loop" /> : <Iconify icon="material-symbols:check" />}
          onClick={() => {
            handleActualSubmission();
          }}>
            {uploadingNow ? `${uploadingAmt}% Uploaded` : 'Submit To Client'}
        </Button>

      </Modal>

      <Modal classNames={{modal: 'modalBox', overlay: 'customOverlay'}} open={makeRequest} onClose={onCloseModal} center>
        <h2>New Request</h2>

        <TextField
          id="title-input"
          label="Title"
          fullWidth
          onChange={handleTitle}
        />

        <br/><br/>

        <TextField
          id="desc-input"
          label="Description"
          multiline
          fullWidth
          minRows={5}
          onChange={handleDesc}
        />

        <br/><br/>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Request Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="type-input"
            value={requestTypeModal}
            label="Request Type"
            onChange={handleChange}
          >
            <MenuItem value={'Full Composition & Recording for OST'}>Full Composition & Recording for OST</MenuItem>
            <MenuItem value={'Sound FX'}>Sound FX</MenuItem>
            <MenuItem value={'Foley'}>Foley</MenuItem>
            <MenuItem value={'Jingle'}>Jingle</MenuItem>
            <MenuItem value={'Narration'}>Narration</MenuItem>
            <MenuItem value={'Cleanup/Editing'}>Cleanup/Editing</MenuItem>
            <MenuItem value={'Re-mix/master'}>Re-mix/master</MenuItem>
            <MenuItem value={'Overdubs'}>Overdubs</MenuItem>
            <MenuItem value={'VR/3D Audio'}>VR/3D Audio</MenuItem>
            <MenuItem value={'Other'}>Other</MenuItem>
          </Select>
        </FormControl>

        <br/><br/>

        <TextField
          id="format-input"
          label="Requested Format (ex: All individual tracks in wav format.)"
          fullWidth
          onChange={handleFormat}
        />

        <br/><br/>

        <section className="container">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className='fileDrop'>
              Drag 'n' drop some files here, or click to select files
              {files.length > 0 ? <><br/> <h4>{files}</h4></> : null}
              </div>
            
          </div>
          
        </section>

        <br/><br/>

        <Button variant="outlined" color="error" fullWidth startIcon={<Iconify icon="foundation:x" />}
          onClick={() => {
            onCloseModal();
          }}>
            Cancel request
        </Button>

          <br/><br/>

        <Button variant="contained" disabled={uploadingNow} fullWidth startIcon={uploadingNow ? <Iconify icon="line-md:loading-loop" /> : <Iconify icon="mdi:check-bold" />}
          onClick={() => {
            submitNewRequest();
          }}>
            {uploadingNow ? `${uploadingAmt}% Uploaded` : 'Submit Request To Queue'}
        </Button>
      </Modal>



        <Modal classNames={{modal: 'modalBox'}} open={openWarning} onClose={handleCloseEditMenu} center>
        <h2>Are you sure?</h2>

        <Button variant="outlined" fullWidth startIcon={<Iconify icon="foundation:x" />}
          onClick={() => {
            handleCloseEditMenu();
          }}>
            Cancel
        </Button>

        <br/><br/>

        <Button variant="contained" color="error" fullWidth startIcon={<Iconify icon="mdi:check-bold" />}
          onClick={() => {
            handleActualDelete();
          }}>
            Delete
        </Button>

      </Modal>

      <Modal classNames={{modal: 'modalBox'}} open={cancelReq} onClose={() => {setCancelReq(null)}} center>
        <h2>Are you sure you want to cancel?</h2>

        <Typography variant="secondary" gutterBottom align='center'>
            Progress related to this request will be stopped & you will not receive any audio submissions for this request. You can always re-queue this request at anytime to be completed.
        </Typography>

        <br/><br/>

        <Button variant="outlined" fullWidth startIcon={<Iconify icon="mdi:check-bold" />}
          onClick={() => {
            setCancelReq(null);
          }}>
            No, I want to leave it alone.
        </Button>

        <br/><br/>

        <Button variant="contained" color="error" fullWidth startIcon={<Iconify icon="foundation:x" />}
          onClick={() => {
            handleCancelActive();
            setCancelReq(null);
          }}>
            I want to cancel this request.
        </Button>

      </Modal>

      <Modal classNames={{modal: 'modalBox'}} open={revisionModal} onClose={() => {setRevisionModal(null)}} center>
        <h2>Anything you'd like to add?</h2>

        <TextField
          id="comment-input"
          label="Explain your revision thoughts..."
          fullWidth
          multiline
          minRows={5}
          onChange={(e) => {setRevisionComment(e.target.value)}}
        />

        <br/> <br/>

        <Button variant="contained" color="secondary" fullWidth startIcon={<Iconify icon="mdi:check-bold" />}
          onClick={() => {
            markRequestRevision();
            setRevisionModal(null);
          }}>
            Submit For Revision
        </Button>

      </Modal>

      <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Requester</TableCell>
            {companyData.plan === 'ADMIN' ? <TableCell>Company</TableCell> : null}
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        
        <DragDropContext onDragEnd={companyData.plan !== 'ADMIN' ? onDragEnd : null}>
          
          <Droppable droppableId="droppable" direction="vertical">
            {(provided, snapshot) => (
              <TableBody
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                
                {REQUESTLIST.map((req, index) => (
                  <Draggable
                    key={req.id}
                    draggableId={req.state === 'In Progress' ? null : `q- ${req.id}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <TableRow
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                        >
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={'User'} src={req.avatarUrl} />
                            <Typography variant="subtitle2" noWrap>
                              {req.username}
                            </Typography>
                            
                          </Stack>
                          
                        </TableCell>
                        {companyData.plan === 'ADMIN' ? <TableCell>
                              {req.company}
                            </TableCell> : null}
                        <TableCell >
                          {req.requestName}
                        </TableCell>
                        <TableCell >
                          {req.requestType}
                        </TableCell>
                        <TableCell >
                          {req.state}
                        </TableCell>
                        <TableCell >
                          {new Date(req.createdAt).toLocaleString()}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => {
                              openRequestDetails(event, req.id);
                            }}>
                            <Iconify icon={'pepicons-pop:dots-y'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
        
      </Table>
    </TableContainer>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem sx={{ color: 'info' }} onClick={() => openRequestDetails(ID)}>
          <Iconify icon={'ph:eye-bold'} sx={{ mr: 2 }} />
          See More
        </MenuItem>
        <MenuItem sx={{ color: 'error.main' }} onClick={deleteRequest}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      </Container>
    </>
  );
}


